import { string } from 'prop-types'
import { Logo } from './index'
import { Links } from '../../constants/Links'

export function GithubLogo({ color }) {
  return (
    <Logo
      href={Links.github}
      id='github'
      color={color}
    >
      M15 6.10352e-05C6.7125 6.10352e-05 0 6.88235 0 15.3795C0 22.1849 4.29375 27.9329 10.2563 29.9707C11.0063 30.1053 11.2875 29.6439 11.2875 29.2402C11.2875 28.8749 11.2688 27.6638 11.2688 26.3757C7.5 27.087 6.525 25.4338 6.225 24.5687C6.05625 24.1265 5.325 22.7616 4.6875 22.3963C4.1625 22.108 3.4125 21.3967 4.66875 21.3774C5.85 21.3582 6.69375 22.4924 6.975 22.9538C8.325 25.28 10.4812 24.6263 11.3438 24.2226C11.475 23.223 11.8688 22.5501 12.3 22.1656C8.9625 21.7811 5.475 20.4547 5.475 14.572C5.475 12.8995 6.05625 11.5154 7.0125 10.4388C6.8625 10.0543 6.3375 8.47796 7.1625 6.36329C7.1625 6.36329 8.41875 5.95958 11.2875 7.93968C12.4875 7.59364 13.7625 7.42063 15.0375 7.42063C16.3125 7.42063 17.5875 7.59364 18.7875 7.93968C21.6563 5.94036 22.9125 6.36329 22.9125 6.36329C23.7375 8.47796 23.2125 10.0543 23.0625 10.4388C24.0187 11.5154 24.6 12.8803 24.6 14.572C24.6 20.4739 21.0938 21.7811 17.7563 22.1656C18.3 22.6462 18.7688 23.569 18.7688 25.0108C18.7688 27.0678 18.75 28.7211 18.75 29.2402C18.75 29.6439 19.0312 30.1245 19.7812 29.9707C22.759 28.9399 25.3465 26.9777 27.1796 24.3602C29.0127 21.7428 29.9991 18.6018 30 15.3795C30 6.88235 23.2875 6.10352e-05 15 6.10352e-05Z
    </Logo>
  )
}

GithubLogo.propTypes = {
  color: string,
}
