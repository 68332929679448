import { string } from 'prop-types'
import { Logo } from './index'
import { Links } from '../../constants/Links'

export function LinkedinLogo({ color }) {
  return (
    <Logo
      href={Links.linkedin}
      id='linkedin'
      color={color}
    >
      M3.7099e-07 2.50637C3.7099e-07 1.84164 0.264063 1.20413 0.734097 0.7341C1.20413 0.264066 1.84163 3.34134e-06 2.50636 3.34134e-06H27.4909C27.8203 -0.000534684 28.1466 0.0639073 28.4511 0.18964C28.7556 0.315372 29.0323 0.499926 29.2653 0.732735C29.4984 0.965543 29.6833 1.24204 29.8093 1.54638C29.9354 1.85073 30.0002 2.17695 30 2.50637V27.4909C30.0004 27.8204 29.9357 28.1467 29.8099 28.4512C29.684 28.7557 29.4993 29.0324 29.2664 29.2654C29.0335 29.4985 28.7569 29.6833 28.4525 29.8093C28.148 29.9354 27.8218 30.0002 27.4923 30H2.50636C2.17711 30 1.85108 29.9351 1.5469 29.8091C1.24273 29.683 0.96637 29.4983 0.733615 29.2654C0.50086 29.0325 0.316271 28.7561 0.190394 28.4518C0.0645181 28.1476 -0.000178767 27.8215 3.7099e-07 27.4923V2.50637ZM11.8745 11.4382H15.9368V13.4782C16.5232 12.3055 18.0232 11.25 20.2773 11.25C24.5986 11.25 25.6227 13.5859 25.6227 17.8718V25.8109H21.2495V18.8482C21.2495 16.4073 20.6632 15.03 19.1741 15.03C17.1082 15.03 16.2491 16.515 16.2491 18.8482V25.8109H11.8745V11.4382ZM4.37455 25.6241H8.74909V11.25H4.37455V25.6227V25.6241ZM9.375 6.56182C9.38325 6.93637 9.3166 7.3088 9.17896 7.65725C9.04133 8.0057 8.83548 8.32314 8.5735 8.59096C8.31152 8.85877 7.99868 9.07156 7.65335 9.21683C7.30802 9.3621 6.93714 9.43694 6.5625 9.43694C6.18786 9.43694 5.81698 9.3621 5.47165 9.21683C5.12632 9.07156 4.81348 8.85877 4.5515 8.59096C4.28952 8.32314 4.08367 8.0057 3.94604 7.65725C3.8084 7.3088 3.74175 6.93637 3.75 6.56182C3.76619 5.82662 4.06962 5.12699 4.5953 4.61276C5.12098 4.09852 5.82712 3.81057 6.5625 3.81057C7.29788 3.81057 8.00402 4.09852 8.5297 4.61276C9.05538 5.12699 9.35881 5.82662 9.375 6.56182Z
    </Logo>
  )
}

LinkedinLogo.propTypes = {
  color: string,
}
